import React from 'react'
import { ButtonContent } from '../global/boton/ButtonContent'


const CardProductBusqueda = ({imagen, name, price, phone}) => {
    return (
        <div className='w-[250px] text-center shadow-lg pb-5 hover:scale-105 hover:transition-all hover:duration-500 hover:shadow-2xl transition-all duration-500 scale-100 rounded-lg'>
            <div className='h-[250px] w-full pb-5'>
                <img src={imagen} alt="not found" className='object-contain w-full h-full rounded-3xl p-2' />
            </div>
            <p>{name}</p>
            <p>{price}</p>
            <ButtonContent btnUrl={`https://wa.me/1${phone}`}/>
        </div>
    )
}

export default CardProductBusqueda